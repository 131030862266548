<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-start auth-bg px-2 p-lg-5-update">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="custom-width">
            <!-- Brand logo-->
            <div class="d-flex justify-content-between">
              <b-link class="brand-logo-update">
                <DefaultLogo />
              </b-link>
              <ul class="lang-sign-up">
                <locale />
              </ul>
            </div>
            <!-- /Brand logo-->
            <b-card-title title-tag="h2" class="font-weight-bold bt-40">
              {{ $t("login.ForgotPass") }}
            </b-card-title>
            <b-card-text class="confirmation-code">
              {{ $t("login.confirmationline1") }} <br />{{
                $t("login.confirmationline2")
              }}
            </b-card-text>
            <h5 class="mb-2">
              {{ maskedEmail(userEmail) }}
            </h5>

            <!-- form -->
            <validation-observer
              ref="forgotPasswordValidation"
              #default="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="validationForm"
              >
                <!-- email -->
                <b-form-group :label="$t('login.ConfirmationCode')">
                  <validation-provider
                    #default="{ errors }"
                    name="confirmation-code"
                    vid="confirmation-code"
                    rules="required"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      class="input-group-merge"
                    >
                      <b-input-group-prepend is-text>
                        <keyIcon />
                      </b-input-group-prepend>
                      <b-form-input
                        id="confirmation-code"
                        v-model="resetCode"
                        :state="errors.length > 0 ? false : null"
                        name="confirmation-code"
                        :placeholder="$t('login.EnterCode')"
                      />
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <b-link
                  class="resennd-code"
                  @click="resendCode"
                  :disabled="!valid"
                >
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.69655 5.00958C2.48133 4.9528 2.30245 4.80336 2.20829 4.60167C2.11413 4.39999 2.11441 4.16689 2.20907 3.96544L3.86983 0.431052C3.99359 0.167666 4.25854 -0.000351074 4.54955 8.20445e-06C4.84057 0.000368805 5.1051 0.169041 5.22821 0.432732L5.96084 2.00197C5.98703 1.99167 6.01407 1.98275 6.04191 1.97529C10.3893 0.81042 14.8578 3.39033 16.0227 7.73768C17.1875 12.085 14.6076 16.5536 10.2603 17.7184C5.91292 18.8833 1.44438 16.3034 0.279515 11.956C-0.0909075 10.5736 -0.0825743 9.17672 0.241859 7.87798C0.342248 7.47612 0.749404 7.23173 1.15127 7.33211C1.55313 7.4325 1.79753 7.83966 1.69714 8.24152C1.43284 9.29951 1.42556 10.4376 1.7284 11.5678C2.67886 15.115 6.32489 17.22 9.87204 16.2695C13.4192 15.3191 15.5242 11.6731 14.5738 8.12591C13.6389 4.63676 10.0958 2.54294 6.60419 3.38L7.34339 4.9633C7.46649 5.22699 7.42595 5.53809 7.23937 5.76143C7.05279 5.98476 6.75387 6.08 6.47248 6.00576L2.69655 5.00958Z"
                      fill="#5E5873"
                    />
                  </svg>
                  {{ $t("login.ResendCode") }}
                </b-link>
                <div class="recaptcha">
                  <re-captcha
                    :valid="valid"
                    :reset="reset"
                    @state="update($event)"
                    @rerender="handleRerender"
                  />
                  <small v-if="!valid || !captchaChecked" class="text-danger">{{
                    hasErrs
                  }}</small>
                </div>
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid || loading || !valid"
                >
                  {{ $t("login.Submit") }}
                </b-button>
              </b-form>
            </validation-observer>
            <b-card-text class="text-center mt-2">
              <b-link :to="{ name: 'login' }">
                <feather-icon icon="ChevronLeftIcon" />
                {{ $t("login.BackLogin") }}
              </b-link>
            </b-card-text>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5 pd-0">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 pd-0"
        >
          <div class="login-bg">
            <LoginImage class="object-login-img" />
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DefaultLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BInputGroupPrepend
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthService from "@/libs/api/auth-service";
import ReCaptcha from "../../layouts/components/ReCaptcha.vue";
import keyIcon from "../../assets/images/pages/login/key.svg";
import LoginImage from "@/assets/images/pages/login-v2.svg";
import Locale from "@/layouts/components/Locale.vue";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    DefaultLogo,
    ValidationProvider,
    ValidationObserver,
    keyIcon,
    BInputGroupPrepend,
    ReCaptcha,
    LoginImage,
    Locale
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      sideImg: require("@/assets/images/pages/forgot-pass-step-2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      confirmationCode: "",
      valid: false,
      hasErrs: "",
      loading: false,
      resetCode: "",
      reset: false,
      captchaChecked: false
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-pass-step-2.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    userEmail() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("email");
    },
    getCode() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("code");
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.resetCode = urlParams.get("code");
  },
  methods: {
    update(value) {
      this.captchaChecked = true;
      this.valid = value;
    },
    handleRerender() {
      // Toggle the value to trigger a re-render of the child component
      this.reset = !this.reset;
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    maskedEmail(email) {
      if (!email) {
        return email;
      }
      var [name, domain] = email.split("@");
      let charToBeMaskedDomain = Math.floor(domain.split(".")[0].length - 1);
      var startIndex = 1;
      for (var k = startIndex; k < startIndex + charToBeMaskedDomain; k++) {
        domain = domain.substring(0, k) + "x" + domain.substring(k + 1);
      }
      if (name.length == 1) return name + "@" + domain;
      else if (name.length == 2) return name[0] + "X@" + domain;
      else if (name.length == 3) return name[0] + "X" + name[2] + "@" + domain;
      else {
        let charToBeMasked = Math.floor(name.length - 3);
        for (var k = startIndex; k < startIndex + charToBeMasked; k++) {
          name = name.substring(0, k) + "x" + name.substring(k + 1);
        }
      }
      return name + "@" + domain;
    },
    async resendCode() {
      let response = await new AuthService().forgotPasswordRequest({
        email: this.userEmail,
        recaptcha_token: this.valid
      });
      if (response && response.data && response.data.success) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Resent confirmation code",
            icon: "EditIcon",
            variant: "success"
          }
        });
      } else {
        this.captchaChecked = true;

        this.handleRerender();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Failed to resend code",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async validationForm() {
      this.loading = true;
      console.log("this log", this.valid, this.reset);
      if (!this.valid || !this.captchaChecked) {
        this.loading = false;
        return (this.hasErrs = this.$t("login.CaptchaError"));
      }

      try {
        let response = await new AuthService().resetPassword({
          code: this.resetCode,
          step: 1,
          recaptcha_token: this.valid
        });
        if (response && response.data && response.data.success) {
          this.$router.push(`/set-new-password?code=${this.resetCode}`);
        } else if (response && response.error && response.error.message) {
          this.captchaChecked = false;
          this.handleRerender();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$router.push("/").catch(() => {});
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "./index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.fill-primary {
  fill: var(--primary);
}
</style>
